<template>
  <div>
    <v-container>
    
   
     <v-row>
       <v-col cols="12">
        <v-toolbar  class="noprint mb-4">
                 <v-icon left color="primary">mdi-account-multiple-check</v-icon> Households
                <v-spacer/>
               
               <v-btn @click="PrintMe()"><v-icon left color="primary">mdi-printer</v-icon> Print</v-btn>
               </v-toolbar>
        <div class="printable_table">
                   <template v-for="(head, index) in HEADS">
                       <div  :key="index" style=" padding: 10px; float: left; width: 230px; height: 210px; border: 1px solid #ddd; text-align: center;">
                         <div style="width: 150px; height: 150px; margin: auto;">
                           <div :id="'qrcode_container_'+ head.id" class="text-center"></div>
                         </div> 
                         <div>{{head.surname}},  {{head.first_name}} {{head.middle_name}}</div> 
                       </div>
                     </template>
                  </div>
       </v-col>
     </v-row>
     </v-container>
  
   </div>
 </template>
 <script>
 import {
   mapMutations
 } from 'vuex'
   export default {
     data: () => ({
         form: false,
         search: '',
         items:[],
         filterText: "Name"
     }),
     beforeCreate: function () {
       if (!this.$session.exists()) {
         this.$router.push('/')
       }
     },
     created() {
        if (this.$session.exists()) {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
           this.getItems()
           this.filterText = "Name"
         }
     },
     computed:{
        windowClass() {
         if(this.$store.state.responsive){
           return "va-reponsive"
         } else {
           return "va-not-reponsive"
         }
       },
       HEADS() {
           var heads = this.items.reduce((res,item)=>{
             if(item.position == "Head of the family") {
               res.push(item)
             }
             return res
           },[])
   
           return heads
         }
       
     },
     methods: {
       ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
       genCode(){
           for(var i=0;i<this.HEADS.length;i++) {
            let v_id=this.HEADS[i].id
           // let voucher=this.HEADS[i].voucher1
               setTimeout(()=> {
                 let _id = "qrcode_container_" + v_id
                 var qrcode = new window.QRCode(document.getElementById(_id), {
                 width : 150,
                 height : 150  
               });
               qrcode.makeCode(v_id.toString());
         
             }, 100)
           }
         
         },
         
       getItems() {
        this.$http.get("data/list").then(response => {
                    response.data.items != null?this.items = response.data.items:this.items=[]
                    setTimeout(()=> {
             this.genCode()
            }, 1000)
                }).catch(e => {
                 console.log(e.data)
             })
      },
      PrintMe() {
        this.$printThis()
      }
   }
 } 
 </script>